<template>
  <div>
    <Header class="header-companylist" />

    <section class="app-ecommerce-details">
      <!-- Alert: No item found -->
      <!-- <b-alert variant="danger" :show="product === undefined">
      <h4 class="alert-heading">Error fetching product data</h4>
      <div class="alert-body">
        No item found with this item slug. Check
        <b-link class="alert-link" :to="{ name: 'company-list' }">
          Shop
        </b-link>
        for other items.
      </div>
    </b-alert> -->

      <!-- Content -->
      <b-card no-body>
        <b-card-body>
          <b-row class="my-2">
            <!--Left : Product Details -->

            <b-col cols="12" md="7">
              <b-row>
                <b-col md="2">
                  <img
                    height="120"
                    width="120"
                    class=""
                    src="../../../../assets/images/pages/companylogo.webp"
                  />
                </b-col>
                <b-col md="10">
                  <!-- Product Name -->
                  <h4 class="text-primary">
                    {{ product.name }} -
                    <span class="">{{ product.shortName }}</span>
                  </h4>
                  <!-- short Name -->
                  <!-- <b-card-text class="item-company mb-0">
                    <span class="text-black">{{ product.shortName }}</span>
                  </b-card-text> -->

                  <!-- Email  || Phone -->
                  <b-card-text>
                    <i class="fa-solid fa-envelope text-primary"></i
                    ><span class="ml-1 text-w500">{{ product.email }}</span>
                    <i class="fa-solid fa-phone ml-1 text-primary"></i
                    ><span class="ml-1 text-w500">{{ product.phone }}</span>
                  </b-card-text>
                  <div class="location-social">
                    <!-- location -->
                    <b-card-text>
                      <i class="fa-solid fa-location-dot text-primary"></i>
                      <span class="ml-1 text-w500"
                        >{{ product.country }} - {{ product.state }}</span
                      >
                    </b-card-text>
                    <!--Socia media-->
                    <div class="item-social">
                      <a
                        v-bind:href="product.facebookUrl"
                        target="_blank"
                        class="ml-2"
                      >
                        <i class="fa-brands fa-facebook-f"></i>
                      </a>
                      <a
                        v-bind:href="product.twitterUrl"
                        target="_blank"
                        class="ml-2"
                      >
                        <i class="fa-brands fa-twitter"></i>
                      </a>
                      <a
                        v-bind:href="product.linkedinUrl"
                        target="_blank"
                        class="ml-2"
                      >
                        <i class="fa-brands fa-linkedin"></i>
                      </a>
                      <a
                        v-bind:href="product.otherUrl"
                        target="_blank"
                        class="ml-2"
                      >
                        <i class="fa-solid fa-at"></i>
                      </a>
                    </div>
                  </div>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="7">
                  <div class="mt-2">
                    <!-- Established -->
                    <b-card-text>
                      <b class="text-black">Established in - </b>
                      <span class="text-w500">{{
                        product.establishment
                      }}</span></b-card-text
                    >
                    <!-- License Number -->
                    <b-card-text>
                      <b class="text-black"> License Number : </b>
                      <span class="text-w500">{{
                        product.licenseNumber
                      }}</span></b-card-text
                    >
                  </div>
                </b-col>
                <b-col md-5>
                  <!--  Ratings -->
                  <div class="ecommerce-details-price d-flex flex-wrap mt-1">
                    <ul class="unstyled-list list-inline pl-1 border-left">
                      <li
                        v-for="star in 5"
                        :key="star"
                        class="ratings-list-item mr-25"
                      >
                        <feather-icon
                          icon="StarIcon"
                          size="18"
                          :class="[
                            { 'fill-current': star <= product.rating },
                            star <= product.rating
                              ? 'text-warning'
                              : 'text-muted',
                          ]"
                        />
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h4>
                      {{ product.category }}:
                      <span>{{ product.price }} {{ product.currency }}</span>
                    </h4>
                  </div>
                </b-col>
              </b-row>

              <hr />
              <!-- Product Description -->
              <h4>About the Company:</h4>
              <b-card-text>{{ product.description }}</b-card-text>

              <hr />
              <h4>tangible and intangible assets the business owns:</h4>
              <b-card-text>{{ product.assets }}</b-card-text>
              <!-- 
              <div class="d-flex flex-column flex-sm-row pt-1">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="btn-cart mr-0 mr-sm-1 mb-1 mb-sm-0"
                  @click="handleCartActionClick(product)"
                >
                  <feather-icon icon="ShoppingCartIcon" class="mr-50" />
                  <span>{{
                    product.isInCart ? "View In Cart" : "Add to Cart"
                  }}</span>
                </b-button>
                <b-button
                  variant="outline-secondary"
                  class="btn-wishlist mr-0 mr-sm-1 mb-1 mb-sm-0"
                  @click="toggleProductInWishlist(product)"
                >
                  <feather-icon
                    icon="HeartIcon"
                    class="mr-50"
                    :class="{ 'text-danger': product.isInWishlist }"
                  />
                  <span>Wishlist</span>
                </b-button>
                <b-dropdown
                  variant="outline-secondary"
                  no-caret
                  toggle-class="btn-icon"
                  class="btn-share"
                  right
                >
                  <template #button-content>
                    <feather-icon icon="Share2Icon" />
                  </template>
                  <b-dropdown-item
                    v-for="icon in [
                      'FacebookIcon',
                      'TwitterIcon',
                      'YoutubeIcon',
                      'InstagramIcon',
                    ]"
                    :key="icon"
                  >
                    <feather-icon :icon="icon" />
                  </b-dropdown-item>
                </b-dropdown>
              </div> -->
            </b-col>
            <!-- Right: Product Image Container -->
            <b-col
              cols="12"
              md="5"
              class="d-flex align-items-center justify-content-center mb-2 mb-md-0"
            >
              <!-- <div class="d-flex align-items-center justify-content-center">
                <b-img
                  :src="product.image"
                  :alt="`Image of ${product.name}`"
                  class="product-img"
                  fluid
                />
              </div> -->
              <div v-viewer class="images">
                <img
                  height="200"
                  width="200"
                  class="p-1"
                  v-for="src in images"
                  :src="src"
                  :key="src"
                />
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="7" class="">
              <div>
                <b-table-simple responsive>
                  <b-tr>
                    <b-td><b>EBITDA Margin</b> </b-td>
                    <b-td>{{ product.EBITDA + "%" }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td><b>Monthly Sales</b> </b-td>
                    <b-td>{{ product.MonthlySales }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td><b>Annual Sales</b> </b-td>
                    <b-td>{{ product.AnnualSales }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td><b>Physical Assets</b> </b-td>
                    <b-td>{{ product.physicalAssets }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td><b>Legal Entity</b> </b-td>
                    <b-td>{{ product.legalEntity }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td><b>industry</b> </b-td>
                    <b-td>{{ product.industry }}</b-td>
                  </b-tr>
                </b-table-simple>
              </div>
            </b-col>
            <b-col cols="12" md="5" class="">
              <h4>Highlights H</h4>
              <b-card-text>{{ product.highlights }}</b-card-text>
            </b-col>
          </b-row>
        </b-card-body>
        <b-row>
          <b-col md="12">
            <companyImagesSlider />
          </b-col>
        </b-row>
        <!-- Features Content -->
        <CompanyDetailsFeatures />
        <!-- <div class="item-features">
          <div class="row text-center">
            <div class="col-12 col-md-4 mb-4 mb-md-0">
              <div class="w-75 mx-auto">
                <feather-icon icon="AwardIcon" size="35" />
                <h4 class="mt-2 mb-1">100% Original</h4>
                <p class="card-text">
                  Chocolate bar candy canes ice cream toffee. Croissant pie
                  cookie halvah.
                </p>
              </div>
            </div>
            <div class="col-12 col-md-4 mb-4 mb-md-0">
              <div class="w-75 mx-auto">
                <feather-icon icon="ClockIcon" size="35" />
                <h4 class="mt-2 mb-1">{{ product.category }}</h4>
                <p class="card-text">
                  the price : {{ product.price }}
                  <span>{{ product.currency }}</span>
                </p>
              </div>
            </div>
            <div class="col-12 col-md-4 mb-4 mb-md-0">
              <div class="w-75 mx-auto">
                <feather-icon icon="ShieldIcon" size="35" />
                <h4 class="mt-2 mb-1">1 Year Warranty</h4>
                <p class="card-text">
                  Cotton candy gingerbread cake I love sugar plum I love sweet
                  croissant.
                </p>
              </div>
            </div>
          </div>
        </div> -->

        <!-- Static Content -->
        <!-- Slider: Related Products -->

        <CompanyDetailsRelated />
      </b-card>
    </section>
    <Footer class="footer-companyList" />
  </div>
</template>

<script>
import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";
import Header from "@/layouts/landpage/Header.vue";
import Footer from "@/layouts/landpage/Footer.vue";
import { useRouter } from "@core/utils/utils";
import store from "@/store";
import { ref } from "@vue/composition-api";
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BImg,
  BCardText,
  BLink,
  BButton,
  BDropdown,
  BDropdownItem,
  BAlert,
  BTableSimple,
  BTr,
  BTd,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import CompanyDetailsFeatures from "./CompanyDetailsFeatures.vue";
import CompanyDetailsRelated from "./CompanyDetailsRelated.vue";
import companyImagesSlider from "./companyImagesSlider.vue";

import { useEcommerceUi } from "../useEcommerce";

export default {
  data() {
    return {
      test: "asdasdasdasdasdsa",
      product: {
        id: 1,
        image: require("../../../../assets/images/pages/companylogo.webp"),
        rating: 2,
        name: "Nutri Booster Healthy Foods Company Nutri Booster Healthy Foods Company Nutri Booster Healthy Foods Company",
        shortName: "ABC",
        establishment: "2022-03-25",
        email: "abc@gmail.com",
        phone: "9621545135412",
        location: "United Arab Emirates-Duabi",
        description:
          " Describe the business  Describe the business  Describe the business  Describe the business   Describe the business  Describe the business  Describe the business  Describe the business ",
        assets:
          "tangible and intangible assets the tangible and intangible assets the tangible and intangible assets the ",

        highlights:
          " Highlights Highlights Highlights  Highlights Highlights Highlights  Highlights Highlights Highlights ",
        EBITDA: "70",
        MonthlySales: "35000",
        AnnualSales: "3232200",
        legalEntity: "Limited liability partnership",
        physicalAssets: "220",
        industry: "industrial industrial industrial",
        licenseNumber: "Ad202552623232QZ",
        country: "UAE",
        state: "Dubai",

        category: "Full Sale",
        currency: "$",
        price: "102560",
        twitterUrl: "//twitter.com",
        facebookUrl: "//fb.com",
        linkedinUrl: "//linked.com",
        otherUrl: "//otherUrl.com",
        SelectedCompanyType: "",
        SelectedCategories: "",

        selectedIndustry: "",
      },

      images: [
        "https://picsum.photos/200/200",
        "https://picsum.photos/300/200",
        "https://picsum.photos/400/200",
        // "https://picsum.photos/500/200",
        // "https://picsum.photos/600/200",
        // "https://picsum.photos/700/200",
      ],
    };
  },

  directives: {
    Ripple,
  },
  components: {
    Header,
    Footer,
    // BSV
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,
    BTableSimple,
    BTr,
    BTd,
    VueViewer,
    // SFC
    CompanyDetailsFeatures,
    CompanyDetailsRelated,
    companyImagesSlider,
  },
  setup() {
    //   const { handleCartActionClick, toggleProductInWishlist } = useEcommerceUi();
    //  const product = ref(null);
    // Remote Data
    //   const fetchProduct = () => {
    // Get product  id from URL
    //    const { route } = useRouter();
    //     const productSlug = route.value.params.slug;
    //    const productId = productSlug.substring(productSlug.lastIndexOf("-") + 1);
    //   store
    //     .dispatch("app-ecommerce/fetchProduct", { productId })
    //     .then((response) => {
    //       product.value = response.data.product;
    //     })
    //     .catch((error) => {
    //       if (error.response.status === 404) {
    //         product.value = undefined;
    //       }
    //     });
    // };
    // UI
    // const selectedColor = ref(null);
    // fetchProduct();
    // return {
    // Fetched Product
    //   product,
    // UI
    //   selectedColor,
    //   handleCartActionClick,
    //   toggleProductInWishlist,
    // };
  },
  created() {
    //$themeConfig.layout.menu.hidden = true;
    //console.log(store.state.appConfig.layout.menu.hidden);
    //console.log(store.state.appConfig.layout.footerType);
    store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", true);
    store.commit("appConfig/UPDATE_FOOTER_CONFIG", { type: "hidden" });
    store.commit("appConfig/UPDATE_NAVBAR_CONFIG", { type: "hidden" });
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";
</style>
<style>
.text-black {
  color: #000;
}
.text-w500 {
  font-weight: 500;
}
</style>
<style scoped>
.breadcrumbs-top {
  margin-top: 65px;
}
.location-social {
  display: flex;
}
.item-social {
  margin-left: 40px;
}
</style>
